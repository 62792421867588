<template>
  <div class="sidebar-left">
    <div class="sidebar">

      <!-- Logged In User Profile Sidebar -->
      <!--<user-profile-sidebar-->
        <!--:shall-show-user-profile-sidebar="shallShowUserProfileSidebar"-->
        <!--:profile-user-data="profileUserData"-->
        <!--@close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"-->
      <!--/>-->

      <!-- Sidebar Content -->
      <div
        class="sidebar-content"
        :class="{'show': mqShallShowLeftSidebar}"
      >

        <!-- Sidebar close icon -->
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>

        <!-- Header -->
        <!--<div class="chat-fixed-search">-->
          <!--<div class="d-flex align-items-center w-100">-->
            <!--<div class="sidebar-profile-toggle">-->
              <!--&lt;!&ndash;badge&ndash;&gt;-->
              <!--&lt;!&ndash;badge-variant="success"&ndash;&gt;-->
              <!--&lt;!&ndash;<b-avatar&ndash;&gt;-->
                <!--&lt;!&ndash;size="42"&ndash;&gt;-->
                <!--&lt;!&ndash;class="cursor-pointer badge-minimal avatar-border-2"&ndash;&gt;-->
                <!--&lt;!&ndash;:src="$domain+ 'assets/images/favicon.ico'"&ndash;&gt;-->
                <!--&lt;!&ndash;variant="transparent"&ndash;&gt;-->
                <!--&lt;!&ndash;@click.native="$emit('show-user-profile')"&ndash;&gt;-->
              <!--&lt;!&ndash;/>&ndash;&gt;-->
              <!--<div class="avatar box-shadow-1 avatar-xl avatar-border mr-1">-->

                <!--&lt;!&ndash;@click.native="shallShowActiveChatContactSidebar=true"&ndash;&gt;-->

                <!--&lt;!&ndash;:src="$domain + 'assets/images/favicon.ico'"&ndash;&gt;-->
                <!--<b-avatar size="42" class="cursor-default">-->
                  <!--<feather-icon size="25" icon="UserIcon"></feather-icon>-->
                <!--</b-avatar>-->
              <!--</div>-->
            <!--</div>-->
            <!--&lt;!&ndash; Search &ndash;&gt;-->
            <!--<b-input-group class="input-group-merge ml-1 w-100 round">-->
              <!--<b-input-group-prepend is-text>-->
                <!--<feather-icon-->
                  <!--icon="SearchIcon"-->
                  <!--class="text-muted"-->
                <!--/>-->
              <!--</b-input-group-prepend>-->
              <!--<b-form-input-->
                <!--v-model="searchQuery"-->
                <!--placeholder="Search..."-->
              <!--/>-->
            <!--</b-input-group>-->
          <!--</div>-->
        <!--</div>-->

        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >

          <!-- Chats Title -->
          <h4 class="chat-list-title d-flex mt-1" >
            {{$t('label_chats')}}

            <b-button v-if="$can('edit', 'chat.tab_details')" @click=" $emit('add-chat', {})" class="ml-auto" variant="success" size="sm">{{$t('label_add')}}</b-button>

          </h4>

          <!-- ***********************Chats *****************************************************-->
          <ul class="chat-users-list chat-list media-list" style="border-top: 1px solid #e9e6e6;">
            <!--<chat-contact-->
             <!---->
            <!--/>-->
            <li
                    v-for="chat in chats"
                    :key="chat.id"
                    :chat="chat"
                    :class="{'active': activeChatContactId === chat.id}"

            >

              <!--:badge="isChatContact"-->
              <!--:badge-variant="resolveAvatarBadgeVariant(chat.status)"-->
              <!--<b-avatar-->
                      <!--size="42"-->
                      <!--:src="$domain+ 'assets/images/favicon.ico'"-->
                      <!--class="badge-minimal"-->
                      <!--variant="transparent"-->
              <!--/>-->
              <b-avatar v-if="chat.icon" :src="$domain + chat.icon"  size="42" class="cursor-default">
              </b-avatar>
              <b-avatar v-else  class="cursor-default">
                <feather-icon size="25" icon="MessageCircleIcon"></feather-icon>
              </b-avatar>

              <template >
                <div class="chat-info flex-grow-1"  @click="$emit('open-chat', chat)">
                  <h5 class="mb-0">
                    {{ chat.name }}
                  </h5>
                  <p v-if="chat.last_message" class="card-text text-truncate">
                    {{ chat.last_message.message }}
                  </p>
                </div>
                <div class="chat-meta text-nowrap">
                  <small class="float-right mb-25 chat-time">{{ formatDate(chat.last_message_at,'MMM DD','YYYY-MM-DD HH:mm:ss') }}</small>
                  <b-badge
                          v-if="chat.unseen_count > 0"
                          pill
                          variant="primary"
                          class="float-right"
                  >
                    {{ chat.unseen_count }}
                  </b-badge>
                </div>
              </template>
            </li>
          </ul>

          <!-- Contacts Title -->
          <!--<h4 class="chat-list-title">-->
            <!--Contacts-->
          <!--</h4>-->

          <!--&lt;!&ndash; Contacts &ndash;&gt;-->
          <!--<ul class="chat-users-list contact-list media-list">-->
            <!--<chat-contact-->
              <!--v-for="contact in filteredContacts"-->
              <!--:key="contact.id"-->
              <!--:user="contact"-->
              <!--tag="li"-->
              <!--@click="$emit('open-chat', contact.id)"-->
            <!--/>-->
          <!--</ul>-->
        </vue-perfect-scrollbar>
      </div>

    </div>



  </div>
</template>

<script>

import {
  BAvatar, BInputGroup, BInputGroupPrepend, BFormInput, BBadge, BButton
} from 'bootstrap-vue'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

// import { ref, computed } from '@vue/composition-api'
import {CHAT_PREFIX as PREFIX} from './moduleHelper'

export default {
  components: {

    // BSV
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput
    , BBadge,
      BButton,

    // 3rd party
    VuePerfectScrollbar,
      // processChatModal

  },
  props: {
    chats: {
      type: Array,
      required: true,
    },

    activeChatContactId: {
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
    data() {
        return {

            PREFIX,

            perfectScrollbarSettings: {
                maxScrollbarLength: 150,
            },
            // searchQuery:'',
            // editedChat: false

        }
    },
    methods:{
        // resolveChatContact(userId){return this.contacts.find(contact => contact.id === userId)},
        // searchFilterFunction(contact){ return contact.fullName.toLowerCase().includes(this.searchQuery.toLowerCase());}
    },
    computed:{
        // filteredChatsContacts(){
        //    return this.chatsContacts.filter(this.searchFilterFunction);
        // },
        // filteredContacts(){
        //     return this.contacts.filter(this.searchFilterFunction);
        // }
    },


}
</script>
